import { chakra } from '@chakra-ui/react';

const SVGIcon = ({ ...props }) => (
  <svg
    width="57"
    height="66"
    viewBox="0 0 57 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.5 51.4797V14.3142L12.4527 23.4932V42.2365L28.5 51.4797Z"
      fill="url(#paint0_linear_2989_19301)"
    />
    <path
      d="M28.5 51.4797V14.3142L12.4527 23.4932V42.2365L28.5 51.4797Z"
      fill="white"
      fillOpacity="0.2"
    />
    <path
      d="M28.5 51.4797V14.3142L44.5473 23.4932V42.2365L28.5 51.4797Z"
      fill="url(#paint1_linear_2989_19301)"
    />
    <path
      d="M28.5 51.4797V14.3142L44.5473 23.4932V42.2365L28.5 51.4797Z"
      fill="white"
      fillOpacity="0.1"
    />
    <path
      d="M28.5 0V14.3142L12.4527 23.4932L0 16.3041L28.5 0Z"
      fill="url(#paint2_linear_2989_19301)"
    />
    <path
      d="M28.5 1.90735e-05V14.3142L44.5473 23.4933L57 16.3041L28.5 1.90735e-05Z"
      fill="url(#paint3_linear_2989_19301)"
    />
    <path
      d="M28.5 1.90735e-05V14.3142L44.5473 23.4933L57 16.3041L28.5 1.90735e-05Z"
      fill="white"
      fillOpacity="0.2"
    />
    <path
      d="M28.5 65.7297V51.4155L12.4527 42.2365L0 49.4257L28.5 65.7297Z"
      fill="url(#paint4_linear_2989_19301)"
    />
    <path
      d="M28.5 65.7297V51.4155L12.4527 42.2365L0 49.4257L28.5 65.7297Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M28.5 65.7297V51.4155L44.5473 42.2365L57 49.4257L28.5 65.7297Z"
      fill="url(#paint5_linear_2989_19301)"
    />
    <path
      d="M28.5 65.7297V51.4155L44.5473 42.2365L57 49.4257L28.5 65.7297Z"
      fill="black"
      fillOpacity="0.2"
    />
    <path
      d="M0 49.4069V16.3041L12.4527 23.4933V42.2365L0 49.4069Z"
      fill="url(#paint6_linear_2989_19301)"
    />
    <path
      d="M0 49.4069V16.3041L12.4527 23.4933V42.2365L0 49.4069Z"
      fill="black"
      fillOpacity="0.2"
    />
    <path
      d="M57 49.4069V16.3041L44.5473 23.4933V42.2365L57 49.4069Z"
      fill="url(#paint7_linear_2989_19301)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2989_19301"
        x1="4.71822"
        y1="78.7369"
        x2="82.0332"
        y2="26.1157"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#222E90" />
        <stop offset="0.119792" stopColor="#89DCE4" />
        <stop offset="0.2174" stopColor="#FAF1F2" />
        <stop offset="0.421875" stopColor="#E9E76E" />
        <stop offset="0.572917" stopColor="#D36F58" />
        <stop offset="0.663" stopColor="#4F4DB0" />
        <stop offset="0.822328" stopColor="#74EEF9" />
        <stop offset="1" stopColor="#222E90" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2989_19301"
        x1="52.2818"
        y1="78.7369"
        x2="-28.206"
        y2="35.4391"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#222E90" />
        <stop offset="0.119792" stopColor="#89DCE4" />
        <stop offset="0.2174" stopColor="#FAF1F2" />
        <stop offset="0.421875" stopColor="#E9E76E" />
        <stop offset="0.572917" stopColor="#D36F58" />
        <stop offset="0.663" stopColor="#4F4DB0" />
        <stop offset="0.822328" stopColor="#74EEF9" />
        <stop offset="1" stopColor="#222E90" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2989_19301"
        x1="-13.7365"
        y1="40.7231"
        x2="19.8548"
        y2="-19.6056"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#222E90" />
        <stop offset="0.119792" stopColor="#89DCE4" />
        <stop offset="0.2174" stopColor="#FAF1F2" />
        <stop offset="0.421875" stopColor="#E9E76E" />
        <stop offset="0.572917" stopColor="#D36F58" />
        <stop offset="0.663" stopColor="#4F4DB0" />
        <stop offset="0.822328" stopColor="#74EEF9" />
        <stop offset="1" stopColor="#222E90" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2989_19301"
        x1="70.7365"
        y1="40.7232"
        x2="37.1452"
        y2="-19.6056"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#222E90" />
        <stop offset="0.119792" stopColor="#89DCE4" />
        <stop offset="0.2174" stopColor="#FAF1F2" />
        <stop offset="0.421875" stopColor="#E9E76E" />
        <stop offset="0.572917" stopColor="#D36F58" />
        <stop offset="0.663" stopColor="#4F4DB0" />
        <stop offset="0.822328" stopColor="#74EEF9" />
        <stop offset="1" stopColor="#222E90" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2989_19301"
        x1="-13.7365"
        y1="25.0066"
        x2="19.8548"
        y2="85.3353"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#222E90" />
        <stop offset="0.119792" stopColor="#89DCE4" />
        <stop offset="0.2174" stopColor="#FAF1F2" />
        <stop offset="0.421875" stopColor="#E9E76E" />
        <stop offset="0.572917" stopColor="#D36F58" />
        <stop offset="0.663" stopColor="#4F4DB0" />
        <stop offset="0.822328" stopColor="#74EEF9" />
        <stop offset="1" stopColor="#222E90" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2989_19301"
        x1="70.7365"
        y1="25.0066"
        x2="37.1452"
        y2="85.3353"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#222E90" />
        <stop offset="0.119792" stopColor="#89DCE4" />
        <stop offset="0.2174" stopColor="#FAF1F2" />
        <stop offset="0.421875" stopColor="#E9E76E" />
        <stop offset="0.572917" stopColor="#D36F58" />
        <stop offset="0.663" stopColor="#4F4DB0" />
        <stop offset="0.822328" stopColor="#74EEF9" />
        <stop offset="1" stopColor="#222E90" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_2989_19301"
        x1="-6.00198"
        y1="73.6844"
        x2="41.3346"
        y2="47.3216"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#222E90" />
        <stop offset="0.119792" stopColor="#89DCE4" />
        <stop offset="0.2174" stopColor="#FAF1F2" />
        <stop offset="0.421875" stopColor="#E9E76E" />
        <stop offset="0.572917" stopColor="#D36F58" />
        <stop offset="0.663" stopColor="#4F4DB0" />
        <stop offset="0.822328" stopColor="#74EEF9" />
        <stop offset="1" stopColor="#222E90" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_2989_19301"
        x1="63.002"
        y1="73.6844"
        x2="15.6654"
        y2="47.3216"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#222E90" />
        <stop offset="0.119792" stopColor="#89DCE4" />
        <stop offset="0.2174" stopColor="#FAF1F2" />
        <stop offset="0.421875" stopColor="#E9E76E" />
        <stop offset="0.572917" stopColor="#D36F58" />
        <stop offset="0.663" stopColor="#4F4DB0" />
        <stop offset="0.822328" stopColor="#74EEF9" />
        <stop offset="1" stopColor="#222E90" />
      </linearGradient>
    </defs>
  </svg>
);

const RarityRare = chakra(SVGIcon);
export default RarityRare;
