import { Rarity } from '@/types/rarity';
import { type ChakraComponent, type IconProps } from '@chakra-ui/react';
import {
  RarityCommonIcon,
  RarityExtremelyRareIcon,
  RarityRareIcon,
  RarityUncommonIcon,
  RarityVeryRareIcon,
} from '@sphere/icons';

const iconMap: {
  [key in Rarity]: ChakraComponent<'svg', IconProps>;
} = {
  [Rarity.Common]: RarityCommonIcon,
  [Rarity.Uncommon]: RarityUncommonIcon,
  [Rarity.Rare]: RarityRareIcon,
  [Rarity.VeryRare]: RarityVeryRareIcon,
  [Rarity.ExtremelyRare]: RarityExtremelyRareIcon,
};

export const RarityIcon = ({ rarity, ...iconProps }: { rarity?: Rarity } & IconProps) => {
  const Icon = iconMap[rarity || Rarity.Common];
  return <Icon {...iconProps} />;
};
