import { chakra } from '@chakra-ui/react';

const SVGIcon = ({ ...props }) => (
  <svg
    width="57"
    height="66"
    viewBox="0 0 57 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.5 51.4797V14.3142L12.4527 23.4932V42.2365L28.5 51.4797Z"
      fill="url(#paint0_linear_2989_19285)"
    />
    <path
      d="M28.5 51.4797V14.3142L12.4527 23.4932V42.2365L28.5 51.4797Z"
      fill="white"
      fillOpacity="0.2"
    />
    <path
      d="M28.5 51.4797V14.3142L44.5473 23.4932V42.2365L28.5 51.4797Z"
      fill="url(#paint1_linear_2989_19285)"
    />
    <path
      d="M28.5 0V14.3142L12.4527 23.4932L0 16.3041L28.5 0Z"
      fill="url(#paint2_linear_2989_19285)"
    />
    <path
      d="M28.5 1.52588e-05V14.3142L44.5473 23.4933L57 16.3041L28.5 1.52588e-05Z"
      fill="url(#paint3_linear_2989_19285)"
    />
    <path
      d="M28.5 1.52588e-05V14.3142L44.5473 23.4933L57 16.3041L28.5 1.52588e-05Z"
      fill="white"
      fillOpacity="0.2"
    />
    <path
      d="M28.5 65.7297V51.4155L12.4527 42.2365L0 49.4257L28.5 65.7297Z"
      fill="url(#paint4_linear_2989_19285)"
    />
    <path
      d="M28.5 65.7297V51.4155L12.4527 42.2365L0 49.4257L28.5 65.7297Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M28.5 65.7297V51.4155L44.5473 42.2365L57 49.4257L28.5 65.7297Z"
      fill="url(#paint5_linear_2989_19285)"
    />
    <path
      d="M28.5 65.7297V51.4155L44.5473 42.2365L57 49.4257L28.5 65.7297Z"
      fill="black"
      fillOpacity="0.2"
    />
    <path
      d="M0 49.4069V16.3041L12.4527 23.4933V42.2365L0 49.4069Z"
      fill="url(#paint6_linear_2989_19285)"
    />
    <path
      d="M0 49.4069V16.3041L12.4527 23.4933V42.2365L0 49.4069Z"
      fill="black"
      fillOpacity="0.2"
    />
    <path
      d="M57 49.4069V16.3041L44.5473 23.4933V42.2365L57 49.4069Z"
      fill="url(#paint7_linear_2989_19285)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2989_19285"
        x1="33.7156"
        y1="23.516"
        x2="13.4423"
        y2="79.9444"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#58D6EC" />
        <stop offset="0.166667" stopColor="#F2F2F4" />
        <stop offset="0.317708" stopColor="#E5C3D4" />
        <stop offset="0.484375" stopColor="#B77FEE" />
        <stop offset="0.583333" stopColor="#83E5EE" />
        <stop offset="0.6875" stopColor="#EBE99C" />
        <stop offset="0.8125" stopColor="#F58AB4" />
        <stop offset="0.895833" stopColor="#7580D9" />
        <stop offset="1" stopColor="#58D6EC" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2989_19285"
        x1="23.2844"
        y1="23.516"
        x2="43.5577"
        y2="79.9444"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#58D6EC" />
        <stop offset="0.166667" stopColor="#F2F2F4" />
        <stop offset="0.317708" stopColor="#E5C3D4" />
        <stop offset="0.484375" stopColor="#B77FEE" />
        <stop offset="0.583333" stopColor="#83E5EE" />
        <stop offset="0.6875" stopColor="#EBE99C" />
        <stop offset="0.8125" stopColor="#F58AB4" />
        <stop offset="0.895833" stopColor="#7580D9" />
        <stop offset="1" stopColor="#58D6EC" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2989_19285"
        x1="66.5"
        y1="-37.5"
        x2="10.5"
        y2="23"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#58D6EC" />
        <stop offset="0.166667" stopColor="#F2F2F4" />
        <stop offset="0.317708" stopColor="#E5C3D4" />
        <stop offset="0.484375" stopColor="#B77FEE" />
        <stop offset="0.583333" stopColor="#83E5EE" />
        <stop offset="0.6875" stopColor="#EBE99C" />
        <stop offset="0.8125" stopColor="#F58AB4" />
        <stop offset="0.895833" stopColor="#7580D9" />
        <stop offset="1" stopColor="#58D6EC" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2989_19285"
        x1="-9.5"
        y1="-37.5"
        x2="46.5"
        y2="23"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#58D6EC" />
        <stop offset="0.166667" stopColor="#F2F2F4" />
        <stop offset="0.317708" stopColor="#E5C3D4" />
        <stop offset="0.484375" stopColor="#B77FEE" />
        <stop offset="0.583333" stopColor="#83E5EE" />
        <stop offset="0.6875" stopColor="#EBE99C" />
        <stop offset="0.8125" stopColor="#F58AB4" />
        <stop offset="0.895833" stopColor="#7580D9" />
        <stop offset="1" stopColor="#58D6EC" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2989_19285"
        x1="66.5"
        y1="103.23"
        x2="10.5"
        y2="42.7297"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#58D6EC" />
        <stop offset="0.166667" stopColor="#F2F2F4" />
        <stop offset="0.317708" stopColor="#E5C3D4" />
        <stop offset="0.484375" stopColor="#B77FEE" />
        <stop offset="0.583333" stopColor="#83E5EE" />
        <stop offset="0.6875" stopColor="#EBE99C" />
        <stop offset="0.8125" stopColor="#F58AB4" />
        <stop offset="0.895833" stopColor="#7580D9" />
        <stop offset="1" stopColor="#58D6EC" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2989_19285"
        x1="-9.5"
        y1="103.23"
        x2="46.5"
        y2="42.7297"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#58D6EC" />
        <stop offset="0.166667" stopColor="#F2F2F4" />
        <stop offset="0.317708" stopColor="#E5C3D4" />
        <stop offset="0.484375" stopColor="#B77FEE" />
        <stop offset="0.583333" stopColor="#83E5EE" />
        <stop offset="0.6875" stopColor="#EBE99C" />
        <stop offset="0.8125" stopColor="#F58AB4" />
        <stop offset="0.895833" stopColor="#7580D9" />
        <stop offset="1" stopColor="#58D6EC" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_2989_19285"
        x1="16.5"
        y1="24.5"
        x2="-3.5"
        y2="73"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#58D6EC" />
        <stop offset="0.166667" stopColor="#F2F2F4" />
        <stop offset="0.317708" stopColor="#E5C3D4" />
        <stop offset="0.484375" stopColor="#B77FEE" />
        <stop offset="0.583333" stopColor="#83E5EE" />
        <stop offset="0.6875" stopColor="#EBE99C" />
        <stop offset="0.8125" stopColor="#F58AB4" />
        <stop offset="0.895833" stopColor="#7580D9" />
        <stop offset="1" stopColor="#58D6EC" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_2989_19285"
        x1="40.5"
        y1="24.5"
        x2="60.5"
        y2="73"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#58D6EC" />
        <stop offset="0.166667" stopColor="#F2F2F4" />
        <stop offset="0.317708" stopColor="#E5C3D4" />
        <stop offset="0.484375" stopColor="#B77FEE" />
        <stop offset="0.583333" stopColor="#83E5EE" />
        <stop offset="0.6875" stopColor="#EBE99C" />
        <stop offset="0.8125" stopColor="#F58AB4" />
        <stop offset="0.895833" stopColor="#7580D9" />
        <stop offset="1" stopColor="#58D6EC" />
      </linearGradient>
    </defs>
  </svg>
);

const RarityUncommon = chakra(SVGIcon);
export default RarityUncommon;
