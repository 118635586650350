import { chakra } from '@chakra-ui/react';

const SVGIcon = ({ ...props }) => (
  <svg
    width="56"
    height="66"
    viewBox="0 0 56 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1_2)">
      <path
        d="M28.5 51.4797V14.3142L12.4527 23.4932V42.2365L28.5 51.4797Z"
        fill="url(#paint0_linear_1_2)"
      />
      <path
        d="M28.5 51.4797V14.3142L12.4527 23.4932V42.2365L28.5 51.4797Z"
        fill="white"
        fillOpacity="0.1"
      />
      <path
        d="M28.5 51.4797V14.3142L44.5473 23.4932V42.2365L28.5 51.4797Z"
        fill="url(#paint1_linear_1_2)"
      />
      <path
        d="M28.5 51.4797V14.3142L44.5473 23.4932V42.2365L28.5 51.4797Z"
        fill="white"
        fillOpacity="0.1"
      />
      <path d="M28.5 0V14.3142L12.4527 23.4932L0 16.3041L28.5 0Z" fill="url(#paint2_linear_1_2)" />
      <path
        d="M28.5 1.52588e-05V14.3142L44.5473 23.4933L57 16.3041L28.5 1.52588e-05Z"
        fill="url(#paint3_linear_1_2)"
      />
      <path
        d="M28.5 1.52588e-05V14.3142L44.5473 23.4933L57 16.3041L28.5 1.52588e-05Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M28.5 65.7297V51.4155L12.4527 42.2365L0 49.4257L28.5 65.7297Z"
        fill="url(#paint4_linear_1_2)"
      />
      <path
        d="M28.5 65.7297V51.4155L12.4527 42.2365L0 49.4257L28.5 65.7297Z"
        fill="black"
        fillOpacity="0.4"
      />
      <path
        d="M28.5 65.7297V51.4155L44.5473 42.2365L57 49.4257L28.5 65.7297Z"
        fill="url(#paint5_linear_1_2)"
      />
      <path
        d="M28.5 65.7297V51.4155L44.5473 42.2365L57 49.4257L28.5 65.7297Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M0 49.4069V16.3041L12.4527 23.4933V42.2365L0 49.4069Z"
        fill="url(#paint6_linear_1_2)"
      />
      <path
        d="M0 49.4069V16.3041L12.4527 23.4933V42.2365L0 49.4069Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M57 49.4069V16.3041L44.5473 23.4933V42.2365L57 49.4069Z"
        fill="url(#paint7_linear_1_2)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1_2"
        x1="70.5"
        y1="-36"
        x2="-0.0270568"
        y2="-25.6782"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC80C" />
        <stop offset="0.119792" stopColor="#FF9900" />
        <stop offset="0.244792" stopColor="#A73A5B" />
        <stop offset="0.416667" stopColor="#F5DF15" />
        <stop offset="0.588542" stopColor="#FF7C00" />
        <stop offset="0.744792" stopColor="#FFD8C1" />
        <stop offset="0.869792" stopColor="#FFF7DC" />
        <stop offset="1" stopColor="#FDC80C" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_2"
        x1="-13.5"
        y1="-36"
        x2="57.0271"
        y2="-25.6782"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDC80C" />
        <stop offset="0.119792" stopColor="#FF9900" />
        <stop offset="0.244792" stopColor="#A73A5B" />
        <stop offset="0.416667" stopColor="#F5DF15" />
        <stop offset="0.588542" stopColor="#FF7C00" />
        <stop offset="0.744792" stopColor="#FFD8C1" />
        <stop offset="0.869792" stopColor="#FFF7DC" />
        <stop offset="1" stopColor="#FDC80C" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1_2"
        x1="28.5"
        y1="2.04521"
        x2="10.5728"
        y2="26.9466"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.171875" stopColor="#FF4539" />
        <stop offset="0.744792" stopColor="#FEA514" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1_2"
        x1="28.5"
        y1="2.04523"
        x2="46.4272"
        y2="26.9466"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.171875" stopColor="#FF4539" />
        <stop offset="0.744792" stopColor="#FEA514" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1_2"
        x1="28.5"
        y1="63.6845"
        x2="10.5728"
        y2="38.7832"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.171875" stopColor="#FF4539" />
        <stop offset="0.744792" stopColor="#FEA514" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1_2"
        x1="28.5"
        y1="63.6845"
        x2="46.4272"
        y2="38.7832"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.171875" stopColor="#FF4539" />
        <stop offset="0.744792" stopColor="#FEA514" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1_2"
        x1="12"
        y1="8.49999"
        x2="-12.9975"
        y2="22.9242"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0D7EC4" />
        <stop offset="0.276787" stopColor="#139EDD" />
        <stop offset="0.621059" stopColor="#BBDBFF" />
        <stop offset="1" stopColor="#FEA514" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1_2"
        x1="45"
        y1="5.99999"
        x2="71.2454"
        y2="21.6022"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0D7EC4" />
        <stop offset="0.276787" stopColor="#139EDD" />
        <stop offset="0.621059" stopColor="#BBDBFF" />
        <stop offset="1" stopColor="#FEA514" />
      </linearGradient>
      <clipPath id="clip0_1_2">
        <rect width="56" height="66" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const RarityVeryRare = chakra(SVGIcon);
export default RarityVeryRare;
