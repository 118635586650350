import { chakra } from '@chakra-ui/react';

const SVGIcon = ({ ...props }) => (
  <svg
    width="57"
    height="66"
    viewBox="0 0 57 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.5 51.4797V14.3142L12.4527 23.4932V42.2365L28.5 51.4797Z"
      fill="url(#paint0_linear_2989_19269)"
    />
    <path
      d="M28.5 51.4797V14.3142L12.4527 23.4932V42.2365L28.5 51.4797Z"
      fill="white"
      fillOpacity="0.2"
    />
    <path
      d="M28.5 51.4797V14.3142L44.5473 23.4932V42.2365L28.5 51.4797Z"
      fill="url(#paint1_linear_2989_19269)"
    />
    <path
      d="M28.5 51.4797V14.3142L44.5473 23.4932V42.2365L28.5 51.4797Z"
      fill="white"
      fillOpacity="0.1"
    />
    <path
      d="M28.5 0V14.3142L12.4527 23.4932L0 16.3041L28.5 0Z"
      fill="url(#paint2_linear_2989_19269)"
    />
    <path
      d="M28.5 3.05176e-05V14.3142L44.5473 23.4933L57 16.3041L28.5 3.05176e-05Z"
      fill="url(#paint3_linear_2989_19269)"
    />
    <path
      d="M28.5 3.05176e-05V14.3142L44.5473 23.4933L57 16.3041L28.5 3.05176e-05Z"
      fill="white"
      fillOpacity="0.2"
    />
    <path
      d="M28.5 65.7297V51.4155L12.4527 42.2365L0 49.4257L28.5 65.7297Z"
      fill="url(#paint4_linear_2989_19269)"
    />
    <path
      d="M28.5 65.7297V51.4155L12.4527 42.2365L0 49.4257L28.5 65.7297Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M28.5 65.7297V51.4155L44.5473 42.2365L57 49.4257L28.5 65.7297Z"
      fill="url(#paint5_linear_2989_19269)"
    />
    <path
      d="M28.5 65.7297V51.4155L44.5473 42.2365L57 49.4257L28.5 65.7297Z"
      fill="black"
      fillOpacity="0.2"
    />
    <path
      d="M0 49.4069V16.3041L12.4527 23.4933V42.2365L0 49.4069Z"
      fill="url(#paint6_linear_2989_19269)"
    />
    <path
      d="M0 49.4069V16.3041L12.4527 23.4933V42.2365L0 49.4069Z"
      fill="black"
      fillOpacity="0.2"
    />
    <path
      d="M57 49.4069V16.3041L44.5473 23.4933V42.2365L57 49.4069Z"
      fill="url(#paint7_linear_2989_19269)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2989_19269"
        x1="33.7156"
        y1="23.516"
        x2="13.4423"
        y2="79.9444"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CBDDD7" />
        <stop offset="0.166667" stopColor="#F2F2F4" />
        <stop offset="0.317708" stopColor="#E8E8E8" />
        <stop offset="0.484375" stopColor="#DCDCDC" />
        <stop offset="0.583333" stopColor="#C4D1D2" />
        <stop offset="0.6875" stopColor="#B1B1AE" />
        <stop offset="0.8125" stopColor="#F1F1F1" />
        <stop offset="0.895833" stopColor="#E0E0E0" />
        <stop offset="1" stopColor="#CDCECE" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2989_19269"
        x1="23.2844"
        y1="23.516"
        x2="43.5577"
        y2="79.9444"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CBDDD7" />
        <stop offset="0.166667" stopColor="#F2F2F4" />
        <stop offset="0.317708" stopColor="#E8E8E8" />
        <stop offset="0.484375" stopColor="#DCDCDC" />
        <stop offset="0.583333" stopColor="#C4D1D2" />
        <stop offset="0.6875" stopColor="#B1B1AE" />
        <stop offset="0.8125" stopColor="#F1F1F1" />
        <stop offset="0.895833" stopColor="#E0E0E0" />
        <stop offset="1" stopColor="#CDCECE" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2989_19269"
        x1="66.5"
        y1="-37.5"
        x2="10.5"
        y2="23"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BCDDE3" />
        <stop offset="0.166667" stopColor="#F2F2F4" />
        <stop offset="0.317708" stopColor="#E3E3E3" />
        <stop offset="0.484375" stopColor="#D7D7D7" />
        <stop offset="0.583333" stopColor="#D9D9D9" />
        <stop offset="0.6875" stopColor="#C8C8C8" />
        <stop offset="0.8125" stopColor="#E1E1E1" />
        <stop offset="0.895833" stopColor="#CECED2" />
        <stop offset="1" stopColor="#C4C4C4" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2989_19269"
        x1="-9.5"
        y1="-37.5"
        x2="46.5"
        y2="23"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#93CCD6" />
        <stop offset="0.166667" stopColor="#F2F2F4" />
        <stop offset="0.317708" stopColor="#E2E2E2" />
        <stop offset="0.484375" stopColor="#E7E7E7" />
        <stop offset="0.583333" stopColor="#C8C8C8" />
        <stop offset="0.6875" stopColor="#E8E7DA" />
        <stop offset="0.8125" stopColor="#D6D6D6" />
        <stop offset="0.895833" stopColor="#C6C7CC" />
        <stop offset="1" stopColor="#BABEBF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2989_19269"
        x1="66.5"
        y1="103.23"
        x2="10.5"
        y2="42.7297"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BCDDE3" />
        <stop offset="0.166667" stopColor="#F2F2F4" />
        <stop offset="0.317708" stopColor="#E3E3E3" />
        <stop offset="0.484375" stopColor="#D7D7D7" />
        <stop offset="0.583333" stopColor="#D9D9D9" />
        <stop offset="0.6875" stopColor="#C8C8C8" />
        <stop offset="0.8125" stopColor="#E1E1E1" />
        <stop offset="0.895833" stopColor="#CECED2" />
        <stop offset="1" stopColor="#C4C4C4" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2989_19269"
        x1="-9.5"
        y1="103.23"
        x2="46.5"
        y2="42.7297"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BCDDE3" />
        <stop offset="0.166667" stopColor="#F2F2F4" />
        <stop offset="0.317708" stopColor="#E3E3E3" />
        <stop offset="0.484375" stopColor="#D7D7D7" />
        <stop offset="0.583333" stopColor="#D9D9D9" />
        <stop offset="0.6875" stopColor="#C8C8C8" />
        <stop offset="0.8125" stopColor="#E1E1E1" />
        <stop offset="0.895833" stopColor="#CECED2" />
        <stop offset="1" stopColor="#C4C4C4" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_2989_19269"
        x1="16.5"
        y1="24.5"
        x2="-3.5"
        y2="73"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CBDDD7" />
        <stop offset="0.166667" stopColor="#F2F2F4" />
        <stop offset="0.317708" stopColor="#E8E8E8" />
        <stop offset="0.484375" stopColor="#DCDCDC" />
        <stop offset="0.583333" stopColor="#C4D1D2" />
        <stop offset="0.6875" stopColor="#B1B1AE" />
        <stop offset="0.8125" stopColor="#F1F1F1" />
        <stop offset="0.895833" stopColor="#E0E0E0" />
        <stop offset="1" stopColor="#CDCECE" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_2989_19269"
        x1="40.5"
        y1="24.5"
        x2="60.5"
        y2="73"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CBDDD7" />
        <stop offset="0.166667" stopColor="#F2F2F4" />
        <stop offset="0.317708" stopColor="#E8E8E8" />
        <stop offset="0.484375" stopColor="#DCDCDC" />
        <stop offset="0.583333" stopColor="#C4D1D2" />
        <stop offset="0.6875" stopColor="#B1B1AE" />
        <stop offset="0.8125" stopColor="#F1F1F1" />
        <stop offset="0.895833" stopColor="#E0E0E0" />
        <stop offset="1" stopColor="#CDCECE" />
      </linearGradient>
    </defs>
  </svg>
);

const RarityCommon = chakra(SVGIcon);
export default RarityCommon;
