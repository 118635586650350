import { chakra } from '@chakra-ui/react';

const SVGIcon = ({ ...props }) => (
  <svg
    width="57"
    height="66"
    viewBox="0 0 57 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.5001 51.4798V14.3142L12.4528 23.4933V42.2365L28.5001 51.4798Z"
      fill="url(#paint0_linear_2989_19344)"
    />
    <path
      d="M28.5001 51.4798V14.3142L12.4528 23.4933V42.2365L28.5001 51.4798Z"
      fill="white"
      fillOpacity="0.1"
    />
    <path
      d="M28.4999 51.4798V14.3142L44.5472 23.4933V42.2365L28.4999 51.4798Z"
      fill="url(#paint1_linear_2989_19344)"
    />
    <path
      d="M28.4999 51.4798V14.3142L44.5472 23.4933V42.2365L28.4999 51.4798Z"
      fill="white"
      fillOpacity="0.1"
    />
    <path
      d="M28.5 0V14.3142L12.4527 23.4932L0 16.3041L28.5 0Z"
      fill="url(#paint2_linear_2989_19344)"
    />
    <path
      d="M28.5 0V14.3142L44.5473 23.4932L57 16.3041L28.5 0Z"
      fill="url(#paint3_linear_2989_19344)"
    />
    <path d="M28.5 0V14.3142L44.5473 23.4932L57 16.3041L28.5 0Z" fill="white" fillOpacity="0.2" />
    <path
      d="M28.5 65.7297V51.4155L12.4527 42.2365L0 49.4257L28.5 65.7297Z"
      fill="url(#paint4_linear_2989_19344)"
    />
    <path
      d="M28.5 65.7297V51.4155L12.4527 42.2365L0 49.4257L28.5 65.7297Z"
      fill="black"
      fillOpacity="0.4"
    />
    <path
      d="M28.5 65.7297V51.4155L44.5473 42.2365L57 49.4257L28.5 65.7297Z"
      fill="url(#paint5_linear_2989_19344)"
    />
    <path
      d="M28.5 65.7297V51.4155L44.5473 42.2365L57 49.4257L28.5 65.7297Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M0 49.4069V16.3041L12.4527 23.4933V42.2365L0 49.4069Z"
      fill="url(#paint6_linear_2989_19344)"
    />
    <path
      d="M0 49.4069V16.3041L12.4527 23.4933V42.2365L0 49.4069Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      d="M57 49.4069V16.3041L44.5473 23.4933V42.2365L57 49.4069Z"
      fill="url(#paint7_linear_2989_19344)"
    />
    <path
      d="M57 49.4069V16.3041L44.5473 23.4933V42.2365L57 49.4069Z"
      fill="white"
      fillOpacity="0.1"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2989_19344"
        x1="16"
        y1="22"
        x2="29"
        y2="51"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9A0745" />
        <stop offset="0.182292" stopColor="#33091B" />
        <stop offset="0.359375" stopColor="#250F18" />
        <stop offset="0.916667" stopColor="#353434" />
        <stop offset="1" stopColor="#3E3E3E" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2989_19344"
        x1="41"
        y1="22"
        x2="28"
        y2="51"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9A0745" />
        <stop offset="0.182292" stopColor="#33091B" />
        <stop offset="0.359375" stopColor="#250F18" />
        <stop offset="0.916667" stopColor="#353434" />
        <stop offset="1" stopColor="#3E3E3E" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2989_19344"
        x1="6.2999"
        y1="4.85837"
        x2="7.36765"
        y2="28.4779"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9A0745" />
        <stop offset="0.182292" stopColor="#33091B" />
        <stop offset="0.359375" stopColor="#250F18" />
        <stop offset="0.916667" stopColor="#353434" />
        <stop offset="1" stopColor="#3E3E3E" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2989_19344"
        x1="50.7001"
        y1="4.85837"
        x2="46.9792"
        y2="35.4078"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9A0745" />
        <stop offset="0.182292" stopColor="#33091B" />
        <stop offset="0.359375" stopColor="#250F18" />
        <stop offset="0.916667" stopColor="#353434" />
        <stop offset="1" stopColor="#3E3E3E" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2989_19344"
        x1="2.5"
        y1="55.5"
        x2="7.26764"
        y2="30.1335"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9A0745" />
        <stop offset="0.182292" stopColor="#33091B" />
        <stop offset="0.359375" stopColor="#250F18" />
        <stop offset="0.916667" stopColor="#353434" />
        <stop offset="1" stopColor="#3E3E3E" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_2989_19344"
        x1="53"
        y1="56"
        x2="43.7216"
        y2="23.0022"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9A0745" />
        <stop offset="0.182292" stopColor="#33091B" />
        <stop offset="0.359375" stopColor="#250F18" />
        <stop offset="0.916667" stopColor="#353434" />
        <stop offset="1" stopColor="#3E3E3E" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_2989_19344"
        x1="2.75266"
        y1="23.1497"
        x2="15.3725"
        y2="47.6768"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9A0745" />
        <stop offset="0.182292" stopColor="#33091B" />
        <stop offset="0.359375" stopColor="#250F18" />
        <stop offset="0.916667" stopColor="#353434" />
        <stop offset="1" stopColor="#3E3E3E" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_2989_19344"
        x1="54.2473"
        y1="23.1497"
        x2="41.6275"
        y2="47.6768"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9A0745" />
        <stop offset="0.182292" stopColor="#33091B" />
        <stop offset="0.359375" stopColor="#250F18" />
        <stop offset="0.916667" stopColor="#353434" />
        <stop offset="1" stopColor="#3E3E3E" />
      </linearGradient>
    </defs>
  </svg>
);

const RarityExtremelyRare = chakra(SVGIcon);
export default RarityExtremelyRare;
